import Link from 'next/link';
import { Fragment, useEffect, useState } from 'react';
import Image from 'next/future/image';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { generateSelectDeviceTitle } from '@/lib/buyback/helpers';
import { PageType, StreamlinedDeviceT } from '@/lib/buyback/types';
import { getEdgeUrl } from '@/lib/getEdgeUrl';
import arrowRightIcon from '@/public/icons/buyback/sell-device/arrow-right.svg';
import howToIcon from '@/public/icons/buyback/sell-device/how-to.svg';
import { BuybackUSConfig } from '@/settings/configs/buyback/us/type';
import ProgressBar from '../ProgressBar';
import FindModel from './FindModel';
import Search from '../landing/Search';

const DefaultImage = ({ src, title }: { src: string; title: string }) => (
  <Image
    alt={title}
    src={src}
    height={100}
    width={100}
    quality={100}
    className="h-full w-auto object-contain"
    loading="lazy"
  />
);
const BrandImage = ({ src, title }: { src: string; title: string }) => (
  <Image
    alt={title}
    src={src}
    height={100}
    width={100}
    quality={100}
    className="h-full w-auto object-contain grayscale"
    loading="lazy"
  />
);

const CardContent = ({
  listing,
  pageType,
}: {
  listing: StreamlinedDeviceT;
  pageType: PageType;
}) => (
  <Fragment>
    <div className="flex items-center gap-4">
      <div className="flex h-14 w-14 items-center justify-center">
        {pageType === PageType.BRAND ? (
          <BrandImage
            src={getEdgeUrl(
              `images/buyback/brands/${listing.text.toLowerCase()}.svg`,
            )}
            title={listing.text}
          />
        ) : (
          <DefaultImage src={listing.image} title={listing.text} />
        )}
      </div>
      <span>{listing.text}</span>
    </div>
    <div className="flex h-8 w-8 items-center justify-center">
      <Image
        alt={listing.text}
        src={arrowRightIcon}
        height={50}
        width={50}
        quality={100}
        className="h-full w-auto object-contain"
      />
    </div>
  </Fragment>
);

const Card = ({
  listing,
  pageType,
  nextStep,
}: {
  listing: StreamlinedDeviceT;
  pageType: PageType;
  nextStep?: (handle: string) => void;
}) => {
  if (!listing.link) return null;

  if (nextStep) {
    return (
      <button
        onClick={() => nextStep(listing?.link ?? '')}
        type="button"
        className="flex cursor-pointer items-center justify-between rounded-md border-2 p-4 transition duration-150 ease-in-out hover:bg-gray-300"
      >
        <CardContent listing={listing} pageType={pageType} />
      </button>
    );
  }

  return (
    <Link
      href={
        pageType === PageType.MODEL
          ? `/buyback/${listing.link}/device`
          : `${listing.link}`
      }
    >
      <a className="flex cursor-pointer items-center justify-between rounded-md border-2 p-4 transition duration-150 ease-in-out hover:bg-gray-300">
        <CardContent listing={listing} pageType={pageType} />
      </a>
    </Link>
  );
};

const SelectDevice = ({
  device,
  pageType,
  progress,
  tradeIn,
}: {
  device: BuybackUSConfig;
  pageType: PageType;
  progress?: {
    step: number;
    steps: number;
  };
  tradeIn?: {
    nextStep: (handle: string) => void;
    previousStep: (() => void) | undefined;
    setDeviceHandle: (h: string) => void;
  };
}) => {
  const router = useRouter();
  const viewTitle = generateSelectDeviceTitle(pageType);

  const [showFindModelModal, setShowFindModelModal] = useState(false);
  const [listings, setListings] = useState<StreamlinedDeviceT[]>(
    device.content as StreamlinedDeviceT[],
  );

  useEffect(() => {
    setListings(device.content as StreamlinedDeviceT[]);
  }, [device]);

  const showHowTo =
    pageType === PageType.MODEL &&
    device.title === 'Apple' &&
    device.category === 'phone';

  return (
    <>
      {tradeIn?.previousStep && (
        <button
          type="button"
          onClick={() => tradeIn.previousStep && tradeIn.previousStep()}
          className="flex items-center gap-2 font-bold text-[#B1B2B2]"
        >
          <div className="h-6 w-6">
            <Image
              alt="Back"
              src={arrowRightIcon}
              height={50}
              width={50}
              quality={100}
              className="h-full w-auto rotate-180 object-contain"
            />
          </div>
          <span className="block">Back</span>
        </button>
      )}
      {progress && (
        <ProgressBar
          pageType={pageType}
          back={() => router.back()}
          step={progress.step}
          steps={progress.steps}
          category={device.category ?? ''}
        />
      )}
      <div
        className={cn({
          'mt-8': progress,
          'mt-4': tradeIn?.previousStep,
        })}
      >
        <div className="flex flex-wrap items-center justify-between gap-x-4">
          <Search tradeIn={tradeIn} />
          <h4 className="mt-4 text-lg font-bold">{viewTitle}</h4>
          {showHowTo && (
            <button
              type="button"
              className="flex items-center gap-2 font-bold text-[#497B7D] underline"
              onClick={() => setShowFindModelModal(true)}
            >
              <div className="mt-[-1px] flex h-4 w-4 shrink-0 items-center justify-center">
                <Image
                  alt="How to find this"
                  src={howToIcon}
                  height={50}
                  width={50}
                  quality={100}
                  className="h-full w-auto object-contain"
                />
              </div>
              How to find this?
            </button>
          )}
        </div>

        <div className="mt-2 flex flex-col gap-2">
          {listings.map((listing) => (
            <Card
              nextStep={tradeIn?.nextStep}
              listing={listing}
              key={listing.text}
              pageType={pageType}
            />
          ))}
        </div>
      </div>
      <FindModel
        showModal={showFindModelModal}
        setShowModal={setShowFindModelModal}
      />
    </>
  );
};

export default SelectDevice;
