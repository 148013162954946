import { useEffect } from 'react';

export function useOutsideClickHandler(ref: any, callback: any) {
  function handleClickOutside(event: any) {
    if (ref.current && !ref.current.contains(event.target)) callback();
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}
