import Image from 'next/future/image';
import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import Link from 'next/link';
import Loading from '@lambda/ui/src/Loading';
import cn from 'classnames';
import { Transition } from '@headlessui/react';
import searchIcon from '@/public/icons/menu/search.svg';
import { useOutsideClickHandler } from '@/lib/common/use-outside-click-handler';
import { ProductDb } from '../../common';

interface SearchResponse {
  products: Array<ProductDb>;
  suggestedProducts: Array<ProductDb>;
}

const fetcher = (url: string) => fetch(url).then((res) => res.json());

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const Search = ({
  tradeIn,
  isLandingPage = false,
}: {
  tradeIn?: {
    setDeviceHandle: (h: string) => void;
  };
  isLandingPage?: boolean;
}) => {
  const [showPanel, setShowPanel] = useState(false);
  const [results, setResults] = useState<SearchResponse>({
    products: [],
    suggestedProducts: [],
  });

  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 300);
  const { products, suggestedProducts } = results;

  const { data, isValidating } = useSWR<SearchResponse>(
    () =>
      debouncedSearchQuery
        ? `/api/buyback/search-offers?q=${debouncedSearchQuery}`
        : null,
    fetcher,
  );

  useEffect(() => {
    if (data) setResults(data);
    else setResults({ suggestedProducts: [], products: [] });
  }, [data]);

  // Handle Outside Click
  const containerRef = useRef(null);
  const handleClose = () => setShowPanel(false);

  useOutsideClickHandler(containerRef, handleClose);

  return (
    <div className="w-full">
      <Transition
        show={showPanel && !!tradeIn?.setDeviceHandle}
        enter="transition-opacity duration-300 ease-in-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300 ease-in-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute top-0 left-0 h-full w-full bg-gray-700/20" />
      </Transition>
      <div
        className={cn('relative z-50 w-full', {
          'max-w-[100%]': !isLandingPage,
          'mt-5 max-w-[500px] md:w-[50vw] lg:w-[40vw]': isLandingPage,
        })}
        ref={containerRef}
      >
        <div
          className={cn('w-full rounded-lg', {
            'border-2 shadow-none': !isLandingPage,
            'border-white': showPanel && tradeIn?.setDeviceHandle,
            'shadow-md': isLandingPage,
          })}
        >
          <input
            onFocus={() => setShowPanel(true)}
            type="text"
            className={cn('w-full rounded-md p-3 pr-11 outline-none', {
              'h-10': isLandingPage,
            })}
            placeholder="Search ex: iphone 11, Macbook pro..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="absolute top-1/2 right-3 h-5 w-5 -translate-y-1/2">
            <Image
              src={searchIcon}
              alt="search icon"
              width="20"
              height="20"
              sizes="100vw"
              className="w-full "
            />
          </div>
        </div>
        {showPanel &&
          (suggestedProducts.length > 0 ||
            products.length > 0 ||
            isValidating) && (
            <div
              className={cn('absolute z-10 w-full rounded-lg bg-white p-2', {
                'top-14': !isLandingPage,
                'top-12 shadow-md': isLandingPage,
                'border-2 shadow-md':
                  !isLandingPage && !tradeIn?.setDeviceHandle,
              })}
            >
              {isValidating ? (
                <Loading />
              ) : (
                <>
                  {suggestedProducts.length > 0 && (
                    <div className="flex w-full flex-col items-start justify-start">
                      <div className="w-full rounded-lg bg-gray-700/10 p-2 text-left text-xs">
                        Suggested Products
                      </div>
                      <div className="mt-2 w-full">
                        {suggestedProducts.map((item) => {
                          if (tradeIn?.setDeviceHandle) {
                            return (
                              <button
                                onClick={() =>
                                  tradeIn.setDeviceHandle(item.slug)
                                }
                                className="block w-full rounded-lg p-2 text-left hover:bg-gray-700/5"
                              >
                                {item.model.split(' - ')[0]}
                              </button>
                            );
                          }

                          return (
                            <Link
                              href={`/buyback/${item.slug}/device`}
                              key={`suggested_product_${item}`}
                            >
                              <a className="block w-full rounded-lg p-2 text-left hover:bg-gray-700/5">
                                {item.model.split(' - ')[0]}
                              </a>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {products.length > 0 && (
                    <div className="mt-2 flex w-full flex-col items-start justify-start">
                      <div className="w-full rounded-lg bg-gray-700/10 p-2 text-left text-xs">
                        Products
                      </div>
                      <div className="mt-2 w-full">
                        {products.map((item) => {
                          if (tradeIn?.setDeviceHandle) {
                            return (
                              <button
                                onClick={() =>
                                  tradeIn.setDeviceHandle(item.slug)
                                }
                                className="block w-full rounded-lg p-2 text-left hover:bg-gray-700/5"
                              >
                                {item.model.split(' - ')[0]}
                              </button>
                            );
                          }

                          return (
                            <Link
                              href={`/buyback/${item.slug}/device`}
                              key={`product_${item.productId}`}
                            >
                              <a className="block w-full rounded-lg p-2 text-left hover:bg-gray-700/5">
                                {item.model.split(' - ')[0]}
                              </a>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
      </div>
    </div>
  );
};

export default Search;
